.navlinkCustom {
    border-radius: 4px;
}

.roundNavlink {
    background-color: #1A1B1E;
    span.mantine-1nksqbd.mantine-NavLink-body {
        display: flex;
        justify-content: center;
        border-radius: 4px;
    }
}

.addPagesWrapper {
    display: flex;
    margin-top: 4px;
    justify-content: space-evenly;
}

.accordian-content {
    .mantine-ukbbnm.mantine-Accordion-content {
        padding: 0;
    }   
}

.add-question-page-row {
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    padding: 0 0 16px 0;
}