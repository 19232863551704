.round-preview {
    position: relative;
    height: calc(100% - 50px);
    align-items: center;
    width: 100%;
    justify-content: center;
}

.question-preview {
    position: relative;
    height: calc(100% - 50px);
}

.question-text {
    color: #C1C2C5
}

.answer-text {
    color: #FFF
}