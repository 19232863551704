.possible-answer-template {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &.moving {
    border: 1px;
    border-color: gray;
    border-style: dotted;
  }
}

.subquestion-template {
  display: flex;
  justify-content: space-between;
  width: 100%;
  &.moving {
    border: 1px;
    border-color: gray;
    border-style: dotted;
  }
}