.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;
  place-items: center;
  height: 70%;
  width: 100vw;
  max-width: 800px;
}

.sub-question {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  color: black;
  padding: 20px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.sub-question-text {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
}
