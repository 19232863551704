.quiz-paper-layout {
  background-color: #1A1B1E;
  border-radius: 8px;
  box-shadow:
    /* offset-x | offset-y | blur-radius | spread-radius | color */
          0 6px 10px 0 hsla(0,0%,0%,0.14),
          0 1px 18px 0 hsla(0,0%,0%,0.12),
          0 3px 5px -1px hsla(0,0%,0%,0.2);
  height: 70%;

  padding: 24px;
  width: 95%;
  margin-top: -15vh;
}

.quiz-paper-layout-wrapper {
  background-color: #0E0E0F;
  border: 1px solid #373A40;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-sidebar-left {
  height: 100%;
  overflow: auto;
  background-color: #1A1B1E;
  border: 1px solid #373A40;
}

.accordion-item {
  padding: 0 !important;
}

.accordion-control {
  padding: 0 !important;
}

