.cookie-panel {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding: 32px;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  font-family: AvenirNext, 'Helvetica Neue', Arial, sans-serif;
  font-size: 13px;

  p {
    margin: 0;
  }

  a {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }

  .buttons {
    text-align: right;
    margin: 10px 0px 10px 0px;
  }
}