html, body {
  height: 100%;
  width: 100%;
  scroll-behavior: smooth;
}

html > body > #root {
  height: 100%;
  min-height: 100%;
  width: 100%;  
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.d-flex {
  display: flex;
}
.d-flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-flex-align-left {
  display: flex;
  align-items: left;
  justify-content: left;
}
.view-upload-image-form {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.light-blur {
  filter: blur(1px);
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 200,
  'GRAD' 0,
  'opsz' 24
}
.table-wrapper {
  overflow-x: auto;
}
.green {
  color: green;
}
.red {
  color: red;
}